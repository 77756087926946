import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginContainer from './modules/auth/container/LoginContainer';
import AccessControl from './modules/auth/component/AccessControl';
import Layout from './Layout';
import PropConfigListContainer from './modules/propConfig/container/PropConfigListContainer';
import PropContainerDetailContainer from './modules/propConfig/container/PropConfigDetailContainer';
import SysStoreListContainer from './modules/sysStore/container/SysStoreListContainer';
import SysStoreDetailContainer from './modules/sysStore/container/SysStoreDetailContainer';
import IntFileListContainer from './modules/intFile/container/IntFileListContainer';
import IntFileDetailContainer from './modules/intFile/container/IntFileDetailContainer';
import IntFileLogDetailContainer from './modules/intFileLog/container/IntFileLogDetailContainer';
import IntFileLogListContainer from './modules/intFileLog/container/IntFileLogListContainer';
import PosJournalListContainer from './modules/posJournal/container/PosJournalListContainer';
import PosJournalDetailContainer from './modules/posJournal/container/PosJournalDetailContainer';
import FileUploadListContainer from './modules/fileUpload/container/FileUploadListContainer';
import FileUploadDetailContainer from './modules/fileUpload/container/FileUploadDetailContainer';
import MediaDirectoryTreeContainer from './modules/mediaManagement/container/MediaDirectoryTreeContainer';
import MediaDirectoryItemContainer from './modules/mediaManagement/container/MediaDirectoryItemContainer';
import UsersListContainer from './modules/users/container/UsersListContainer';
import StoreItemsListContainer from './modules/inventory/container/StoreItemsListContainer';
import StoreItemDetailContainer from './modules/inventory/container/StoreItemDetailContainer';
import SysApiLogDetailContainer from './modules/sysApilog/container/SysApiLogDetailContainer';
import SysApiLogsListContainer from './modules/sysApilog/container/SysApiLogListContainer';
import SysEventsListContainer from './modules/sysEvents/container/SysEventsListContainer';
import SysEventsDetailsContainer from './modules/sysEvents/container/SysEventsDetailsContainer';

const AuthRequiredContainer = () => {
    return (
        <>
            <Layout>
                <AccessControl>
                    <Routes>
                        <Route
                            path="/pos-journal"
                            element={<PosJournalListContainer />}
                        />
                        <Route
                            path="/pos-journal/detail"
                            element={<PosJournalDetailContainer />}
                        />
                        <Route
                            path="/prop-confg"
                            element={<PropConfigListContainer />}
                        />
                        <Route
                            path="/prop-confg/detail"
                            element={<PropContainerDetailContainer />}
                        />
                        <Route
                            path="/system-store"
                            element={<SysStoreListContainer />}
                        />
                        <Route
                            path="/system-store/detail"
                            element={<SysStoreDetailContainer />}
                        />
                        <Route
                            path="/integration-file"
                            element={<IntFileListContainer />}
                        />
                        <Route
                            path="/integration-file/detail"
                            element={<IntFileDetailContainer />}
                        />
                        <Route
                            path="/integration-file-log"
                            element={<IntFileLogListContainer />}
                        />
                        <Route
                            path="/integration-file-log/detail"
                            element={<IntFileLogDetailContainer />}
                        />

                        <Route
                            path="/file-upload-queue"
                            element={<FileUploadListContainer />}
                        />
                        <Route
                            path="/file-upload-queue/detail"
                            element={<FileUploadDetailContainer />}
                        />
                        <Route
                            path="/media-management"
                            element={<MediaDirectoryTreeContainer />}
                        />
                        <Route
                            path="/media-management/files"
                            element={<MediaDirectoryItemContainer />}
                        />
                        <Route
                            path="/users-management"
                            element={<UsersListContainer />}
                        />
                        <Route
                            path="/store-items"
                            element={<StoreItemsListContainer />}
                        />
                        <Route
                            path="/store-item/details"
                            element={<StoreItemDetailContainer />}
                        />

                       <Route
                            path="/sys-apilogs"
                            element={<SysApiLogsListContainer />}
                        />
                        <Route
                            path="/sys-apilogs/details"
                            element={<SysApiLogDetailContainer />}
                        />
                        <Route
                            path="/sys-events"
                            element={<SysEventsListContainer />}
                        />
                         <Route
                            path="/sys-events/details"
                            element={<SysEventsDetailsContainer/>}
                        />
                    </Routes>
                </AccessControl>
            </Layout>
        </>
    );
};

const AppRoutes = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<LoginContainer />} />
            <Route element={<AuthRequiredContainer />} path="/ui/*" />
        </Routes>
    </BrowserRouter>
);

export default AppRoutes;
