import { initialSysStoresState, actions } from "./action";

export const sysStoreReducer = (state = initialSysStoresState, action) => {
    switch (action.type) {
        case actions.SYSSTORE_GET_ALL_SUCCESS:
            return {
                ...state,
                sysstoreList: action.payload ? action.payload : {}
            };
        case actions.SYSSTORE_UPDATE_SUCCESS:
            return {
                ...state,
                sysstore: action.payload?.id ? {...action.payload, action: 'update'} : {}
            };
        case actions.SYSSTORE_CREATE_SUCCESS:
            return {
                ...state,
                sysstore: action.payload?.id ? {...action.payload, action: 'create'} : {}
            };
        case actions.SYSSTORE_DELETE_SUCCESS:
            
            return {
                ...state,
                
                sysstore: action.payload?.id ? {...action.payload, action: 'delete'} : {}
            };
        case actions.SYSSTORE_SYNC_STORES_SUCCESS:
            return {
                ...state,
                sysstoreSynced:  action?.payload?.synced
            };
        case actions.SYSSTORE_RESET_SUCCESS:
            return {
                ...state,
                sysstore: {}
            };
        case actions.SYSSTORE_SET_FILTERS_REQUEST:
            return {
                ...state,
                filters: action.payload
            };
        default:
            return state;
    }
};
