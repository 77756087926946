import { createAction } from '@reduxjs/toolkit';
import { actions as authActions } from './modules/auth/store/action';
import { actions as propConfigActions } from './modules/propConfig/store/action';
import { actions as sysStoreActions } from './modules/sysStore/store/action';
import { actions as intFileactions } from './modules/intFile/store/action';
import { actions as intFileLogactions } from './modules/intFileLog/store/action';
import { actions as fileUploadActions } from './modules/fileUpload/store/action';
import { actions as posJournalActions } from './modules/posJournal/store/action';
import { actions as mediaActions } from './modules/mediaManagement/store/action';
import { actions as usersActions } from './modules/users/store/action';
import { actions as initialInventoryActions } from './modules/inventory/store/action';
import {actions as initialSysApiLogState} from './modules/sysApilog/store/action';
import {actions as initialSysEventsState} from './modules/sysEvents/store/action';

const actionTypes = {
    ...authActions,
    ...propConfigActions,
    ...sysStoreActions,
    ...intFileactions,
    ...intFileLogactions,
    ...fileUploadActions,
    ...posJournalActions,
    ...mediaActions,
    ...usersActions,
    ...initialInventoryActions,
    ...initialSysApiLogState,
    ...initialSysEventsState,
};

const appActions = {};

Object.entries(actionTypes).forEach(([key, value]) => {
    appActions[key] = createAction(value);
});

export default appActions;