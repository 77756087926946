
import React from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { yesNoOptions } from '../../../utils/mics';

const SysEventsFilterForm = ({ handleSearch, handleReset, onFormValuesChange }) => {

    const formik= useFormik (

        {
            initialValues :{
                storeId: '',
                metaDataType: '',
                cmdType: '',
                cmdValidity: '',
                cmdDate: '',
                cmdTime: '',
                processed: '',
                processedOn: '',
                date_from :'',
                date_till :''
            },       

        onSubmit : (values) => {
            const data = {
                ...values,
                date_from: values.date_from ? moment(values.date_from).format('YYYY-MM-DD') : '',
                date_till: values.date_till ? moment(values.date_till).format('YYYY-MM-DD') : '',
                cmdDate: values.cmdDate ? moment(values.cmdDate).format('YYYY-MM-DD') : '',
                cmdTime: values.cmdTime ? moment(values.cmdTime).format('HH:mm:ss') : '',
                processedOn: values.processedOn ? moment(values.processedOn).format('YYYY-MM-DD') : '',
            };
            handleSearch(data);
            if (onFormValuesChange) {
                onFormValuesChange(data);
            }
        },
    });

    const handleClear = () => {

        formik.resetForm();
        handleReset();

    };

    const handleChange = (e) => {

        formik.handleChange(e);
        if (onFormValuesChange) {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value };
            onFormValuesChange(updatedValues);
        }

    }

    const renderFormArea = () => {

        return (
            <div className="grid p-2">
                <div className="p-fluid formgrid grid w-full">

                    <div className="field col-12 md:col-3">
                        <label htmlFor="storeId">Store Id</label>
                        <InputText
                            id="storeId"
                            name="storeId"
                            type="text"
                            value={formik.values.storeId}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="field col-12 md:col-3">
                        <label htmlFor="cmdType">CMD Type</label>
                        <InputText
                            id="cmdType"
                            name="cmdType"
                            type="text"
                            value={formik.values.cmdType}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="field col-12 md:col-3">
                        <label htmlFor="cmdDate">CMD Date</label>
                        <Calendar
                            id="cmdDate"
                            value={formik.values.cmdDate}
                            onChange={handleChange}
                            dateFormat="dd-mm-yy"
                            showIcon
                        />
                    </div>

                    <div className="field col-12 md:col-3">
                        <label htmlFor="cmdTime">CMD Time</label>
                        <Calendar
                            id="cmdTime"
                            value={formik.values.cmdTime}
                            onChange={handleChange}
                            dateFormat="dd-mm-yy HH-mm-ss"
                            showIcon
                            timeOnly
                            showSeconds={true}
                        />
                    </div>

                    <div className="field col-12 md:col-3">
                        <label htmlFor="cmdValidity">CMD Validity</label>
                        <InputText
                            id="cmdValidity"
                            name="cmdValidity"
                            type="text"
                            value={formik.values.cmdValidity}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="field col-12 md:col-3">
                        <label htmlFor="title">Processed</label>
                         <Dropdown 
                            value={formik.values.processed}
                            onChange={handleChange}
                            options={yesNoOptions} 
                            id="processed"
                            name="processed"
                            optionLabel="name"
                            optionValue='code'
                        />
                    </div>

                    <div className="field col-12 md:col-3">
                        <label htmlFor="processedOn">Processed On</label>
                        <Calendar
                            id="processedOn"
                            value={formik.values.processedOn}
                            onChange={handleChange}
                            dateFormat="dd-mm-yy"
                            showIcon
                        />
                    </div>

                    <div className="field col-12 md:col-3">
                        <label htmlFor="date_from">Date From</label>
                        <Calendar
                            id="date_from"
                            value={formik.values.date_from}
                            onChange={handleChange}
                            dateFormat="dd-mm-yy"
                            showIcon
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="date_till">Date Till</label>
                        <Calendar
                            id="date_till"
                            value={formik.values.date_till}
                            onChange={handleChange}
                            dateFormat="dd-mm-yy"
                            showIcon
                        />
                    </div>
                </div>
                <div className="grid px-2 py-4">
                    <Button
                        type="submit"
                        label="Search"
                        className="p-button-success mr-2"
                    />
                    <Button
                        type="button"
                        label="Clear"
                        severity="secondary"
                        onClick={handleClear}
                    />
                </div>
            </div>
        );

    };

    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
}

export default SysEventsFilterForm;

