import {
    generateDefaultState,
    getActionTypes,
} from '../../../utils/reduxHelper';

export const actions = getActionTypes('sysEvents', [
    'GET_ALL',
    'UPDATE',
    'CREATE',
    'DELETE',
]);

export const initialSysEventsState = {
    ...generateDefaultState('sysEvents', [
        'GET_ALL',
        'UPDATE',
        'CREATE',
        'DELETE',
    ]),
};
