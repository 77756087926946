import { all } from "redux-saga/effects";
import authWatcher from "../modules/auth/store/watcher";
import propConfigWatcher from "../modules/propConfig/store/watcher";
import sysStoreWatcher from "../modules/sysStore/store/watcher"
import intFileWatcher from "../modules/intFile/store/watcher"
import intFileLogWatcher from "../modules/intFileLog/store/watcher";
import intFileUploadWatcher from "../modules/fileUpload/store/watcher";
import posJournalWatcher from '../modules/posJournal/store/watcher';
import mediaWatcher from '../modules/mediaManagement/store/watcher';
import usersWatcher from '../modules/users/store/watcher';
import invetoryWatcher from "../modules/inventory/store/watcher";
import sysApiLogsWatcher from "../modules/sysApilog/store/watcher";
import sysEventsWatcher from "../modules/sysEvents/store/watcher"

export default function* rootSaga() {
  yield all([
    authWatcher(),
    propConfigWatcher(),
    sysStoreWatcher(),
    intFileWatcher(),
    intFileLogWatcher(),
    intFileUploadWatcher(),
    posJournalWatcher(),
    mediaWatcher(),
    usersWatcher(),
    invetoryWatcher(),
    sysApiLogsWatcher(),
    sysEventsWatcher()
  ]);
}
