import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../../appActions';
import ButtonRefreshIcon from '../../../assets/icons/refresh-svg.svg';
import PrimeTable from '../../../components/PrimeTable';
import IntFileLogFilterForm from './IntFileLogFilterForm';
import { Tooltip } from 'primereact/tooltip';
import DownloadFile from '../../../components/DownloadFile';

const IntFileLogList = () => {

    const { state } = useLocation();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const intFileLogList = useSelector((state) => state.intFileLog.intfileLogList);

    const defaultParams = {
        pageno: 1,
        pagesize: 10,
        sort: 'createdOn',
        sortOrder : -1,
        fileId: state?.filterByFileId || '',
        logType: "ERROR"
    };

    const [formValues, setFormValues] = useState(defaultParams);

    useEffect(() => {
        dispatch(appActions.INTFILELOG_GET_ALL_REQUEST(defaultParams));
    }, [dispatch]);

    const handleRefresh = useCallback(() => {
        dispatch(appActions.INTFILELOG_GET_ALL_REQUEST(defaultParams));
    }, [dispatch, defaultParams]);

    const handleSearch = (params) => {

        const searchParams = {
            ...params,
            pagesize: params.pagesize || defaultParams?.pagesize,
            pageno: params.pageno || defaultParams?.pageno,
            sort: params.sort || defaultParams?.sort,
            sortOrder: params.sortOrder || defaultParams?.sortOrder,
            logType: params.logType || defaultParams?.logType,
        };

        dispatch(
            appActions.INTFILELOG_GET_ALL_REQUEST(searchParams),
        );
    };

    const handleViewRedirect = (record) => {
        const data = intFileLogList?.data?.find(t => t.id == record.id);
        navigate('/ui/integration-file-log/detail', { state: { data, isView: true } });
    }

    const handleFormValuesChange = (values) => {
        setFormValues(values);
    };

    const renderHeader = () => {
        return (
            <>
               <div className="d-flex flex-row gap-10 align-items-center">
                    <h1 className="page-header flex">Integration File Log</h1>
                    <img
                        title="Refresh"
                        onClick={handleRefresh}
                        className="refreshtree mr-auto"
                        src={ButtonRefreshIcon}
                        alt="RefreshButtonImage"
                    />
                    <DownloadFile filePath={'/usr/dxp/logs/dxp-vssap-service.log'} tooltipText={'Download Application logs'}/>
                </div>
            </>
        );
    };

    const actionTemplate = useCallback(
        (rowData) => (
            <div className="flex gap-10 justify-content-center">
                <span className="cursor-pointer tp" onClick={() => handleViewRedirect(rowData)} data-pr-tooltip="View">
                    <i className="pi pi-eye text-blue-500 text-base"></i>
                </span>
            </div>
        ),
        [handleViewRedirect],
    );

    const onFilterChangeCallback  = useCallback(
        (event) => {

            const { sortField, sortOrder, rows, page } = event;
            
            const params = {...formValues, pagesize: rows, pageno: page, sort: sortField, sortOrder: sortOrder };

            setFormValues(prevFormValues => ({
                ...prevFormValues,
                sort: sortField, 
                sortOrder: sortOrder,
                pagesize: rows,
                pageno: page
            }));

            handleSearch(params); 
        },

        [handleSearch, formValues],
    );

    const parseLogText = (logText) => {
        try {
            const json = JSON.parse(logText);
            if (typeof json === 'object' && json !== null) {
                return json?.response;
            }else{
                return logText
            }
        } catch (e) {
            // If parsing fails, return the original logText
            return '';
        }
        return '';
    }

    const logTextTemplate = row => {
        const { logText } = row
        const logTestStr = parseLogText(logText)
        return logTestStr
    }
    
    const columns = [
        { field: 'fileId', header: 'File Id', sortable: true },
        { field: 'storeId', header: 'Store Id', sortable: true },
        { field: 'logGroup', header: 'Log Group', sortable: true, width: '120px', },
        { field: 'logType', header: 'Log Type' },
        { field: 'logText', header: 'Log Text', sortable: true, template: logTextTemplate },
        { field: 'createdOn', header: 'Created On', sortable: true, width: '120px', maxWidth: "150px"  },
        // { field: 'lastUpdated', header: 'Last Updated', sortable: true },
        // { field: 'lastUpdatedBy', header: 'Last Updated By', sortable: true },
    ];

    
    const renderList = () => {
        return (
            <>
                <Tooltip target=".tp" position="bottom" />
               <div className='pv-15 ph-15'>
                    {renderHeader()}
                </div>
                <div className="bg-white mx-3 pv-15 ph-15 br-6 shadow-white">
                    <IntFileLogFilterForm 
                        handleSearch={handleSearch} 
                        handleReset={handleRefresh} 
                        onFormValuesChange={handleFormValuesChange} 
                    />
                    <PrimeTable
                        list={intFileLogList}
                        columns={columns}
                        actionColumn={actionTemplate}
                        onFilterChangeCallback={(e) => {
                            onFilterChangeCallback(e);
                        }}
                        tableName='integration_file_logs'
                        isLazy={true}
                    />
                </div>
            </>
        );
    };

    return <div className="form_height">{renderList()}</div>;
};

export default IntFileLogList;
